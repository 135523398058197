<header class="header" [class.active]="active">
  <section class="header-sponsors">
    <span>patrocinadores</span>
    <img *ngFor="let p of patrocinadores" [src]="p.imagem" [alt]="p.nome" [title]="p.nome" loading="lazy" />
  </section>
  <nav class="header-nav">
    <a class="logo" routerLink="/inicio" title="Black Dragons | e-Sports">
      <img src="assets/logo.svg" alt="Black Dragons | e-Sports" title="Black Dragons | e-Sports" class="logo__pic"
        loading="lazy" />
    </a>
    <button class="header-menu-toggle" (click)="openMenu = !openMenu">
      <i class="fas fa-bars"></i>
    </button>
    <ul class="header-menu" [class.active]="openMenu">
      <li class="header-menu__item" routerLinkActive="active">
        <a (click)="openMenu = false" routerLink="/inicio" class="header-menu__link" title="Home">Home</a>
      </li>
      <li class="header-menu__item" routerLinkActive="active">
        <a (click)="openMenu = false" routerLink="/sobre" class="header-menu__link" title="Sobre">Sobre</a>
      </li>
      <!-- <li class="header-menu__item" routerLinkActive="active">
                <a href="https://www.fallenstore.com.br/categoria/83" target="_blank" class="header-menu__link"
                    title="Loja">Loja</a>
            </li> -->
      <li class="header-menu__item" routerLinkActive="active">
        <a (click)="openMenu = false" routerLink="/noticias" class="header-menu__link" title="Notícias">Notícias</a>
      </li>
      <li class="header-menu__item" routerLinkActive="active">
        <a (click)="openMenu = false" routerLink="/todos/%/times" class="header-menu__link" title="Nossos times">Nossos
          times</a>
      </li>
      <li class="header-menu__item" routerLinkActive="active">
        <a (click)="openMenu = false" routerLink="/patrocinadores" class="header-menu__link"
          title="Patrocinadores">Patrocinadores</a>
      </li>
      <li class="header-menu__item" routerLinkActive="active">
        <a (click)="openMenu = false" routerLink="/agenda" class="header-menu__link" title="Agenda">Agenda</a>
      </li>
      <!-- <li class="header-menu__item" routerLinkActive="active">
                <a (click)="openMenu = false" routerLink="/configuracoes" class="header-menu__link"
                    title="CFGs">CFGs</a>
            </li> -->
      <li class="header-menu__item" routerLinkActive="active">
        <a (click)="openMenu = false" routerLink="/assista" class="header-menu__link" title="Assista">Assista</a>
      </li>
      <li class="header-menu__item" routerLinkActive="active">
        <a (click)="openMenu = false" routerLink="/comunidade" class="header-menu__link"
          title="Comunidade">Comunidade</a>
      </li>
      <li class="header-menu__item" routerLinkActive="active">
        <a (click)="openMenu = false" routerLink="/contato" class="header-menu__link" title="Contato">Contato</a>
      </li>
      <li class="header-menu__item">
        <a [href]="contatoGeral.linkTwitter" class="header-menu__link" title="Twitter" rel="Twitter" target="_blank">
          <span>twitter</span>
          <i class="fab fa-twitter twitter-color"></i>
        </a>
        <a [href]="contatoGeral.linkInstagram" class="header-menu__link" title="Instagram" rel="Instagram"
          target="_blank">
          <span>instagram</span>
          <i class="fab fa-instagram instagram-gradient"></i>
        </a>
        <a [href]="contatoGeral.linkFacebook" class="header-menu__link" title="facebook" rel="facebook" target="_blank">
          <span>facebook</span>
          <i class="fab fa-facebook-square facebook-color"></i>
        </a>
        <a [href]="contatoGeral.linkYoutube" class="header-menu__link" title="Instagram" rel="Instagram" target="_blank"
          *ngIf="contatoGeral.linkYoutube">
          <span>youtube</span>
          <i class="fab fa-youtube youtube-color"></i>
        </a>
        <a [href]="contatoGeral.linkTiktok" class="header-menu__link" title="tiktok" rel="TikTok" target="_blank"
          *ngIf="contatoGeral.linkTiktok">
          <span>tiktok</span>
          <i class="fab fa-tiktok tiktok-color"></i>
        </a>
        <a [href]="contatoGeral.linkLinkedin" class="header-menu__link" title="linkedin" rel="linkedin" target="_blank"
          *ngIf="contatoGeral.linkLinkedin">
          <span>linkedin</span>
          <i class="fab fa-linkedin-in linkedin-color"></i>
        </a>

      </li>
    </ul>
    <div class="header-backdrop" [class.active]="openMenu" (click)="openMenu = !openMenu"></div>
  </nav>
</header>