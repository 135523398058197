import { Cabecalho } from "./../../models/cabecalho.model";
import { ActivatedRoute, Router } from "@angular/router";
import { SiteService } from "./../../services/site.service";
import { Subscription } from "rxjs";
import { Jogo } from "./../../models/jogo.model";
import { Time } from "./../../models/time.model";
import { Component, OnInit } from "@angular/core";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-times",
  templateUrl: "./times.component.html",
  styleUrls: ["./times.component.scss"],
})
export class TimesComponent implements OnInit {
  title: string = "Nossos times";
  titleBold: string;
  background: string = "assets/banner.png";

  times: Time[] = [];
  jogo: Jogo = new Jogo();

  buscarTimesSubscription: Subscription;
  buscarJogoSubscription: Subscription;

  constructor(
    public siteService: SiteService,
    public router: Router,
    public route: ActivatedRoute,
    public helper: HelperService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((param) => {
      if (typeof param.idJogo === "number") {
        this.buscarJogo(param.idJogo);
      } else {
        if (localStorage.getItem("cabecalhos")) {
          const cabecalho: Cabecalho = JSON.parse(
            localStorage.getItem("cabecalhos")
          );
          this.background = cabecalho.imagemNossosTimes;
        }
      }
      this.buscarTimes(param.idJogo);
    });
    this.helper.setCanonicalURL();
  }

  ngOnDestroy() {
    this.buscarTimesSubscription.unsubscribe();
    if (this.buscarJogoSubscription) this.buscarJogoSubscription.unsubscribe();
  }

  buscarTimes(idJogo) {
    this.buscarTimesSubscription = this.siteService
      .buscarTimes(-99, -99, idJogo)
      .subscribe((res: Time[]) => (this.times = res));
  }

  buscarJogo(idJogo) {
    this.buscarJogoSubscription = this.siteService
      .buscarJogo(idJogo)
      .subscribe((res: Jogo) => {
        this.jogo = res;
        this.title += ` - `;
        this.titleBold = this.jogo.nome;
        this.background = this.jogo.imagem;
      });
  }

  verTime(time: Time) {
    const slugJogo = this.route.snapshot.params["slugJogo"];
    const idJogo = this.route.snapshot.params["idJogo"];
    this.router.navigate([
      `/${slugJogo}/${idJogo}/times/${time.slug}/${time.id}`,
    ]);
  }
}
