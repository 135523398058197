<footer class="footer">
  <div class="footer-wrapper">
    <div class="top">
      <div class="logo">
        <img src="assets/logo.svg" alt="Black Dragons | e-Sports" title="Black Dragons | e-Sports" class="logo__pic"
          loading="lazy" />
      </div>
      <ul class="footer-menu">
        <li class="footer-menu__item" routerLinkActive="active">
          <a routerLink="/inicio" class="footer-menu__link" title="Início">Início</a>
        </li>
        <li class="footer-menu__item" routerLinkActive="active">
          <a routerLink="/sobre" class="footer-menu__link" title="Sobre">Sobre</a>
        </li>
        <!-- <li class="footer-menu__item" routerLinkActive="active">
                    <a href="https://www.fallenstore.com.br/categoria/83" target="_blank" class="footer-menu__link"
                        title="Loja">Loja</a>
                </li> -->
        <li class="footer-menu__item" routerLinkActive="active">
          <a routerLink="/noticias" class="footer-menu__link" title="Notícias">Notícias</a>
        </li>
        <li class="footer-menu__item" routerLinkActive="active">
          <a routerLink="/times" class="footer-menu__link" title="Nossos times">Nossos times</a>
        </li>
        <li class="footer-menu__item" routerLinkActive="active">
          <a routerLink="/patrocinadores" class="footer-menu__link" title="Patrocinadores">Patrocinadores</a>
        </li>
        <li class="footer-menu__item" routerLinkActive="active">
          <a routerLink="/agenda" class="footer-menu__link" title="Agenda">Agenda</a>
        </li>
        <li class="footer-menu__item" routerLinkActive="active">
          <a routerLink="/assista" class="footer-menu__link" title="Assista">Assista</a>
        </li>
        <li class="footer-menu__item" routerLinkActive="active">
          <a routerLink="/comunidade" class="footer-menu__link" title="Comunidade">Comunidade</a>
        </li>
        <li class="footer-menu__item" routerLinkActive="active">
          <a routerLink="/contato" class="footer-menu__link" title="Contato">Contato</a>
        </li>
        <li class="footer-menu__item">
          <a [href]="contatoGeral.linkTwitter" target="_blank" class="footer-menu__link" title="Twitter">
            <span>twitter</span>
            <i class="fab fa-twitter twitter-color"></i>
          </a>
          <a [href]="contatoGeral.linkInstagram" target="_blank" class="footer-menu__link" title="Instagram">
            <span>instagram</span>
            <i class="fab fa-instagram instagram-gradient"></i>
          </a>
          <a [href]="contatoGeral.linkFacebook" target="_blank" class="footer-menu__link" title="facebook"
            rel="facebook">
            <span>facebook</span>
            <i class="fab fa-facebook-square facebook-color"></i>
          </a>
          <a [href]="contatoGeral.linkYoutube" target="_blank" class="footer-menu__link" title="Youtube" rel="Youtube">
            <span>youtube</span>
            <i class="fab fa-youtube youtube-color"></i>
          </a>
          <a [href]="contatoGeral.linkTiktok" class="footer-menu__link" title="tiktok" rel="TikTok" target="_blank"
            *ngIf="contatoGeral.linkTiktok">
            <span>tiktok</span>
            <i class="fab fa-tiktok tiktok-color"></i>
          </a>
          <a [href]="contatoGeral.linkLinkedin" class="footer-menu__link" title="linkedin" rel="linkedin"
            target="_blank" *ngIf="contatoGeral.linkLinkedin">
            <span>linkedin</span>
            <i class="fab fa-linkedin-in linkedin-color"></i>
          </a>
        </li>
      </ul>
      <button class="footer-back-to-top" (click)="scrollToTop()">
        <i class="fas fa-chevron-up"></i>
      </button>
    </div>
    <div class="bottom">
      <span class="footer-copyrights">
        Copyright &copy; 2020 www.blackdragons.com.br. Todos os direitos
        reservados.
      </span>
      <a class="footer-mail" [href]="'mailto:' + contatoGeral.email">
        <div class="footer-mail__icon">
          <img src="assets/icon/envelope.svg" alt="E-mail" title="E-mail" loading="lazy" />
        </div>
        <span>{{ contatoGeral.email }}</span>
      </a>
      <div class="footer-technologies">
        <a href="https://www.behance.net/OniStudio" target="_blank" class="footer-technologies__link"
          title="Oni Studio">
          <img src="assets/logo_oni.svg" alt="Oni Studio" title="Oni Studio" loading="lazy" />
        </a>
        <a href="https://yourway.digital" target="_blank"
          class="footer-technologies__link footer-technologies__link--yourway" title="YourWay Digital">
          <img src="assets/yourway.png" alt="YourWay Digital" title="YourWay Digital" loading="lazy" />
        </a>
      </div>
    </div>
  </div>
</footer>